import { FC, useState } from "react"
import { Header } from "components/header/header"
import { Button } from "components/button/button"
import { useModal } from "context/modal-context"
import "./confirmation-modal.scss"

type Props = {
  title: string
  description?: string
  children?: React.ReactNode
  className?: string
  confirmLabel?: string
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "outlined"
    | "danger"
    | "success"
    | "link"
    | "icon-btn"
  onConfirm: () => any
  onCancel?: () => void
  hasConfirmFailed?: boolean
  setHasConfirmedFailed?: (value: React.SetStateAction<boolean>) => void
}

export const ConfirmationModal: FC<Props> = ({
  title,
  description,
  children,
  className = "",
  confirmLabel = "Confirm",
  variant = "primary",
  hasConfirmFailed = false,
  setHasConfirmedFailed,
  onConfirm,
  onCancel,
}: Props) => {
  const { unSetModal } = useModal()
  const [loading, setLoading] = useState(false)

  return (
    <div className={`confirmation-modal ${className}`}>
      <Header title={title} description={description} />
      {children}
      <div className="flex justify-between mt-7">
        <Button
          variant="outlined"
          onClick={() => {
            onCancel && onCancel()
            unSetModal()
          }}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          variant={variant}
          onClick={() => {
            setLoading(true)
            Promise.all([onConfirm()])
              .then(() => {
                setLoading(false)
                if (!hasConfirmFailed) {
                  setHasConfirmedFailed && setHasConfirmedFailed(false)
                }
              })
              .finally(unSetModal)
          }}
        >
          {confirmLabel}
        </Button>
      </div>
    </div>
  )
}
