import { FC, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import "./image-dropzone.scss"

type DropzoneProps = {
  image: any
  setImage: React.Dispatch<React.SetStateAction<any>>
  className?: string
  setDeleteLogo?: React.Dispatch<React.SetStateAction<boolean>>
}

export const ImageDropzone: FC<DropzoneProps> = ({
  image,
  setImage,
  className,
  setDeleteLogo,
}) => {
  const [preview, setPreview] = useState<any>("")
  const [isError, setIsError] = useState<boolean>(false)
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    maxSize: 2048000,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles: any, fileRejections: any) => {
      if (fileRejections.length === 1) {
        setImage("")
        setIsError(true)
      } else {
        setIsError(false)
        setImage(acceptedFiles[0])
        setPreview(URL.createObjectURL(acceptedFiles[0]))
      }
    },
  })

  return (
    <>
      {image === "" || !image ? (
        <>
          <div
            {...getRootProps({
              className: `dropzone ${className} ${
                isError ? "border-red-600" : "border-blue-600"
              }`,
            })}
          >
            <input className="input-zone" {...getInputProps()} />
            <div className="text-center dropzone-content">
              <span className="grid grid-cols-1 justify-items-center">
                <Icon icon={ICONS.UPLOAD_FILE} />
                {!isDragActive ? (
                  <>
                    <span className="text-headline font-medium mt-2">
                      <span
                        className="text-blue-600 hover:text-blue-800 cursor-pointer"
                        onClick={open}
                      >
                        Browse
                      </span>{" "}
                      or Drag & drop here
                    </span>
                    <span className="text-gray-400 text-caption-1 mt-1">
                      Accepted file types: JPG, PNG. Max size of 2MB
                    </span>
                  </>
                ) : (
                  <span className="text-headline font-medium mt-2">Drop file here</span>
                )}
              </span>
            </div>
          </div>
          {isError && (
            <div className="max-w-[320px]">
              <span className="text-red-600 text-caption-2 font-semibold">
                Warning! There was an error and your file could not be uploaded.
              </span>
              <br></br>
              <span className="text-red-600 text-caption-2 font-semibold">
                Please check the requirements above!
              </span>
            </div>
          )}
        </>
      ) : (
        <div className="image-dropped">
          <img
            className="object-contain w-auto h-11"
            src={preview || image}
            onLoad={() => {
              URL.revokeObjectURL(preview || image)
            }}
          />
          <Button
            variant="icon-btn"
            className="justify-self-end"
            onClick={() => {
              setImage("")
              setDeleteLogo?.(true)
            }}
          >
            <Icon icon={ICONS.DELETE} />
          </Button>
        </div>
      )}
    </>
  )
}
