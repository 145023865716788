import { Button } from "components/button/button"
import {
  SelectRetailerUncontrolled,
  UploadType,
} from "components/select-retailer-uncontrolled/select-retailer-uncontrolled"
import { RetailerSelectorOption } from "common/types/RetailerSelectorOption.type"

type StepTwoProps = {
  step: number
  setStep: (step: number) => void
  selectedRetailers: RetailerSelectorOption[]
  setSelectedRetailers: React.Dispatch<React.SetStateAction<RetailerSelectorOption[]>>
  selectedUploadType: UploadType
  setSelectedUploadType: React.Dispatch<React.SetStateAction<UploadType>>
  importDataMapping: () => void
  onCancel: () => void
}

export const StepTwo = ({
  step,
  setStep,
  selectedRetailers,
  setSelectedRetailers,
  selectedUploadType,
  setSelectedUploadType,
  importDataMapping,
  onCancel,
}: StepTwoProps) => {
  const isNextStepDisabled = selectedRetailers.length === 0

  return (
    <div className="flex flex-col h-full">
      <div className="step-body">
        <>
          <div className="flex justify-between border-b pb-3">
            <div>
              <span className="text-title-3 font-bold">Upload Type</span>
              <div>
                <span className="text-sub-headline font-semibold">
                  Upload Master Version
                </span>{" "}
                - can select more than one site.{" "}
                <span className="text-sub-headline font-semibold">
                  Upload as a Source
                </span>{" "}
                - can create a custom retailer.
              </div>
            </div>
            <Button onClick={onCancel} variant="outlined" className="self-end">
              Cancel Upload
            </Button>
          </div>
          <div className="h-[calc(100%-59px)] flex flex-col">
            <SelectRetailerUncontrolled
              selectedRetailers={selectedRetailers}
              setSelectedRetailers={setSelectedRetailers}
              selectedUploadType={selectedUploadType}
              setSelectedUploadType={setSelectedUploadType}
            />
          </div>
        </>
      </div>
      <div className="step-button-bar sticky bottom-0 bg-white z-10">
        <Button
          variant="outlined"
          onClick={() => {
            setStep(step - 1)
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          disabled={isNextStepDisabled}
          onClick={() => {
            importDataMapping()
          }}
        >
          Next Step
        </Button>
      </div>
    </div>
  )
}
