import { saveMapApi } from "api/endpoints/data-mapping-import.api"
import { ProductImportCsvModel } from "common/models/ProductImportCsvModel"
import { Button } from "components/button/button"
import { ImageComponent } from "components/image/image"
import { Table } from "components/table/table"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RootState } from "store"
import { FourthStepTypes } from "../fieldMappingPage"

type StepFourProps = {
  step: number
  setStep: (step: number) => void
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
  data: FourthStepTypes
  onCancel: () => void
}

export const StepFour = ({
  step,
  setStep,
  setIsSaving,
  data,
  onCancel,
}: StepFourProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const getColumns = () => {
    const tooltipInstance = useSelector((state: RootState) => state.tippyTarget.target)

    return [
      {
        header: "Rank",
        isSortable: true,
        accessorKey: "rank",
        size: 100,
      },
      {
        header: "Brand",
        isSortable: true,
        accessorKey: "brand",
        size: 150,
      },
      {
        header: "Title",
        isSortable: true,
        accessorKey: "title",
        size: 250,
      },
      {
        header: "Main Image",
        isSortable: true,
        cell: ({
          row: {
            original: { images },
          },
        }: any) => {
          if (!Array.isArray(images)) {
            return
          }

          if (images[0] === "" || images[0] === null || images === null) {
            return
          }

          if (images && Array.isArray(images) && images[0]) {
            return (
              <ImageComponent
                src={images[0]}
                alt={images[0]}
                tooltipInstance={tooltipInstance}
                className="image h-10 w-10 rounded border border-gray-100"
              />
            )
          }
        },
      },
      {
        header: "Product ID",
        isSortable: true,
        accessorKey: "upc",
      },
    ]
  }

  const onSaveMap = async () => {
    if (!selectedWorkspaceId || !data.dataMappingId) {
      return
    }
    await dispatch.retailers.setIsShouldOpenModal(false)
    setIsSaving(true)

    saveMapApi(selectedWorkspaceId, data.dataMappingId).then((res) => {
      if (res) {
        navigate(`/main/workspaces/${selectedWorkspaceId}/edit/upload`, {
          state: 1,
        })
      }
    })
  }

  return (
    <div className="flex flex-col h-full px-[25px]">
      <div className="flex justify-between items-center pl-[25px] border-b pb-3">
        <div className="flex flex-col gap-[5px]">
          <div className="text-title-3 font-bold pt-[35px]">Sample Preview</div>
          <div>
            In the table below you can see the information from the{" "}
            <span className="font-semibold">file imported and mapped</span> to our
            application. Only the <span className="font-semibold">first 10 Rows</span> are
            visible to this preview.
          </div>
        </div>
        <Button variant="outlined" className="self-end" onClick={onCancel}>
          Cancel Upload
        </Button>
      </div>
      <div className="flex flex-col flex-grow pt-[35px] h-[calc(100%-100px)]">
        <Table
          columns={getColumns()}
          data={data.rows
            .map((row: ProductImportCsvModel, index: number) => ({
              ...row,
              rank: index + 1,
            }))
            .slice(0, 10)}
        />
      </div>
      <div className="step-button-bar sticky bottom-0 bg-white z-10">
        <Button
          variant="outlined"
          onClick={() => {
            setStep(step - 1)
          }}
          className="mr-3"
        >
          Back
        </Button>
        <Button variant="success" onClick={onSaveMap}>
          Save Map
        </Button>
      </div>
    </div>
  )
}
