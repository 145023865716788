import { RematchDispatch } from "@rematch/core"
import { WORKSPACE_ID } from "api/api"
import {
  deleteRetailerApi,
  getCustomRetailersByAuditTypeApi,
  getFavoriteRetailersByAuditTypeApi,
  getFrequentlyUsedByAuditTypeApi,
  getRetailerByAuditTypeApi,
  getRetailerCategoriesApi,
  getRetailersApi,
  postRetailerApi,
  putRetailerApi,
} from "api/endpoints/retailers.api"
import { AuditType } from "common/enums/AuditType.enum"
import { RetailerModel } from "common/models/RetailerModel"
import { RState } from "store"

type State = Readonly<{
  allRetailers: RetailerModel[]
  frequentlyUsedRetailers: RetailerModel[]
  retailerCategories: string[]
  customRetailers: RetailerModel[]
  favoriteRetailers: RetailerModel[]
  shouldOpenModal: boolean
}>

const model = {
  state: {
    allRetailers: [],
    frequentlyUsedRetailers: [],
    retailerCategories: [],
    customRetailers: [],
    favoriteRetailers: [],
    shouldOpenModal: false,
  } as State,
  reducers: {
    loadedAllRetailers: (state: State, payload: any): State => ({
      ...state,
      allRetailers: payload,
    }),
    loadedFrequentlyUsedRetailers: (state: State, payload: any): State => ({
      ...state,
      frequentlyUsedRetailers: payload,
    }),
    loadedRetailerCategories: (state: State, payload: any): State => ({
      ...state,
      retailerCategories: payload,
    }),
    loadedCustomRetailers: (state: State, payload: any): State => ({
      ...state,
      customRetailers: payload,
    }),
    loadedFavoriteRetailers: (state: State, payload: any): State => ({
      ...state,
      favoriteRetailers: payload,
    }),
    setIsShouldOpenModal: (state: State, payload: boolean): State => ({
      ...state,
      shouldOpenModal: payload,
    }),
  },
  effects: (dispatch: RematchDispatch<any>) => ({
    async fetchAllRetailers() {
      const retailers = await getRetailersApi(WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedAllRetailers(retailers)
      }
      return retailers
    },
    async fetchAllRetailersByAuditType(auditType: AuditType) {
      const retailers = await getRetailerByAuditTypeApi(auditType, WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedAllRetailers(retailers)
      }
      return retailers
    },
    async fetchFrequentlyUsedRetailers(auditType: AuditType) {
      const retailers = await getFrequentlyUsedByAuditTypeApi(auditType, WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedFrequentlyUsedRetailers(retailers)
      }
      return retailers
    },
    async fetchCustomRetailers() {
      const retailers = await getCustomRetailersByAuditTypeApi(WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedCustomRetailers(retailers)
      }
      return retailers
    },
    async fetchFavoriteRetailers() {
      const retailers = await getFavoriteRetailersByAuditTypeApi(WORKSPACE_ID)

      if (retailers) {
        dispatch.retailers.loadedFavoriteRetailers(retailers)
      }
      return retailers
    },
    async fetchRetailerCategories(payload: string) {
      const retailerCategories = await getRetailerCategoriesApi(payload)

      if (retailerCategories) {
        dispatch.retailers.loadedRetailerCategories(retailerCategories)
      }
      return retailerCategories
    },
    async createRetailerSource(payload: { body: any }, rootState: RState) {
      const existingCustomRetailers = rootState.retailers.customRetailers

      const retailers = await postRetailerApi(payload.body)

      const updatedRetailers = [...existingCustomRetailers, retailers]

      if (retailers) {
        dispatch.retailers.loadedCustomRetailers(updatedRetailers)
      }
      return retailers
    },
    async deleteRetailer(retailerId: string, rootState: RState) {
      const existingRetailers = rootState.retailers.customRetailers
      const retailers = await deleteRetailerApi(WORKSPACE_ID, retailerId)

      const updatedRetailers = existingRetailers.filter((r) => r.id !== retailerId)

      if (retailers) {
        dispatch.retailers.loadedCustomRetailers(updatedRetailers)
      }
      return retailers
    },
    async updateRetailer(payload: { retailerId: string; body: any }, rootState: RState) {
      const existingRetailers = rootState.retailers.customRetailers
      const retailers = await putRetailerApi(payload.body)

      const updatedRetailerIndex = existingRetailers.findIndex(
        (r) => r.id === payload.retailerId,
      )

      const updatedRetailers = [...existingRetailers]
      updatedRetailers[updatedRetailerIndex] = retailers

      if (retailers) {
        dispatch.retailers.loadedCustomRetailers(updatedRetailers)
      }
      return retailers
    },
  }),
}

export default model
