import { Input } from "components/form-components/input/input"
import { ICONS, Icon } from "components/icon/icon"
import { useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import { toast } from "react-toastify"
import { ERRORS } from "utils/constants"

type DragAndDropUploadZoneProps = {
  className?: string
  file: File | null
  setFile: React.Dispatch<React.SetStateAction<File | null>>
}

export const DragAndDropUploadZone = ({
  className,
  file,
  setFile,
}: DragAndDropUploadZoneProps) => {
  const [isDragging, setIsDragging] = useState(false)

  const uploadInputRef = useRef<HTMLInputElement>(null)

  const { getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.type === "text/csv") {
        setFile(acceptedFiles[0])
      }
      setIsDragging(false)
    },
    onDragEnter: () => {
      setIsDragging(true)
    },
    onDragLeave: () => {
      setIsDragging(false)
    },
    onDropRejected: (fileRejections) => {
      if (fileRejections.length > 1) {
        return toast.error("Too many files uploaded!")
      }
      const { errors } = fileRejections[0]
      if (errors[0].code === "file-invalid-type") {
        toast.error("Invalid file type! Only CSV files are accepted for this upload.")
      } else {
        toast.error(errors[0]?.message)
      }
    },
    onError: () => {
      toast.error(ERRORS.requestSuccessfulWithNoResolution.message)
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const lowercaseFileName = file.name.toLowerCase()
      if (lowercaseFileName.endsWith(".csv")) {
        setFile(event.target.files[0])
      } else {
        toast.error("Invalid file type! Only CSV files are accepted for this upload.")
      }
    }
  }

  const handleBrowseClick = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click()
    }
  }

  return (
    <div {...getRootProps()} className={`${className} flex items-center`}>
      {isDragging ? (
        <div className="flex flex-col w-full">
          <div>
            <Icon
              icon={ICONS.CLOUD_ARROW_UP}
              className="text-blue-600 mx-auto"
              size={9}
            />
            <div className="text-body font-medium pt-2.5 pb-1 w-fit mx-auto">
              <span className="text-black">Drop file here</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {file ? (
            <div>
              <Icon
                icon={ICONS.CHECK_BADGE_OUTLINE}
                className="text-blue-600 mx-auto"
                size={9}
              />
              <div className="text-body font-medium pt-2.5 pb-1 w-fit mx-auto">
                <span className="text-black">File Uploaded</span>
              </div>
              <div className={"relative mx-5 mt-10"}>
                <Input
                  onClick={open}
                  required
                  value={file?.name}
                  onChange={() => {}}
                  className={`font-medium`}
                />
                <div
                  className={`absolute right-2 top-[14px] text-red-600 font-medium  ${
                    file?.name ? "visible" : "hidden"
                  }`}
                  onClick={() => setFile(null)}
                >
                  <Icon icon={ICONS.CANCEL} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <Icon
                icon={ICONS.CLOUD_ARROW_UP}
                className="text-blue-600 mx-auto"
                size={9}
              />
              <div className="text-body font-medium pt-2.5 pb-1 w-fit mx-auto">
                <span
                  className="text-blue-600 cursor-pointer hover:underline"
                  onClick={handleBrowseClick}
                >
                  Browse{" "}
                  <input
                    className="hidden"
                    ref={uploadInputRef}
                    type="file"
                    onChange={handleFileChange}
                  />
                </span>
                <span className="text-black">or Drag & drop file here</span>
              </div>
              <div className="text-caption-2 text-gray-400 w-fit mx-auto">
                Accepted file types: CSV
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
