import { Button } from "components/button/button"
import { Icon, ICONS } from "components/icon/icon"
import { useModal } from "context/modal-context"

export const DeleteRetailerModal = ({ onDelete }: { onDelete: () => void }) => {
  const { unSetModal } = useModal()

  return (
    <div className="text-center">
      <div className="flex flex-col items-center ">
        <Icon icon={ICONS.DELETE} size={10} className="text-red-600" />
      </div>
      <div className="font-bold text-title-3 mt-8 mb-12">
        Are you sure you want to delete this upload?
      </div>
      <div className="flex justify-between items-center">
        <Button variant="outlined" onClick={unSetModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            onDelete()
            unSetModal()
          }}
        >
          Yes, Delete it
        </Button>
      </div>
    </div>
  )
}
