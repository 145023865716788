import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootDispatch, RootState } from "store"
import { Button } from "components/button/button"
import { Input } from "components/form-components/input/input"
import { ImageDropzone } from "components/image-dropzone/image-dropzone"
import { Textarea } from "components/form-components/textarea/textarea"

type AddNewSourceProps = {
  closeModal: () => void
  retailer?: any
}

export const SourceModal = ({ closeModal, retailer }: AddNewSourceProps) => {
  const dispatch = useDispatch<RootDispatch>()
  const [dataSourceName, setDataSourceName] = useState(retailer?.label ?? "")
  const [description, setDescription] = useState(retailer?.notes ?? "")
  const [logo, setLogo] = useState<File | null | string>(
    retailer ? retailer.logoUrl : null,
  )
  const [deleteLogo, setDeleteLogo] = useState(false)
  const selectedWorkspaceId = useSelector(
    (state: RootState) => state.workspaces.selectedWorkspaceId,
  )
  const disableSave = !retailer
    ? !dataSourceName.trim() || !description.trim()
    : !dataSourceName.trim() ||
      !description.trim() ||
      (dataSourceName === retailer.name &&
        description === retailer.label &&
        logo === retailer.logoUrl)

  const handleSave = () => {
    console.log(dataSourceName)
    const formData = new FormData()
    formData.append("name", dataSourceName)
    formData.append("notes", description)
    formData.append("logo", logo as File)
    formData.append("deleteLogo", deleteLogo as any)
    formData.append("workspaceId", selectedWorkspaceId ?? "")
    formData.append("retailerId", retailer?.value)

    if (retailer) {
      dispatch.retailers
        .updateRetailer({
          body: formData,
          retailerId: retailer?.value,
        })
        .then(() => closeModal())
    } else {
      dispatch.retailers
        .createRetailerSource({
          body: formData,
        })
        .then(() => closeModal())
    }
  }

  return (
    <div className="flex flex-col max-w-[520px] gap-[30px]">
      <div className="flex flex-col gap-3">
        <div className="text-title-3 font-semibold">
          {retailer ? "Edit Source" : "Add New Source"}
        </div>
        {retailer ? (
          <div className="text-body text-gray-500">
            If you need to customize this source, here you can easily edit the name,
            <br />
            description and also you can change the logo.
          </div>
        ) : (
          <div className="text-body text-gray-500">
            If our list of retailers does not help you, here you can create a custom one.
            <br />
            It is not mandatory to add a logo.
          </div>
        )}
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-caption-1 font-medium">Enter Data Source Name *</span>
        <Input
          placeholder="Type here..."
          onChange={(event: any) => {
            setDataSourceName(event.target.value)
          }}
          value={dataSourceName}
        />
      </div>
      <div className="flex flex-col gap-[5px]">
        <span className="text-caption-1 font-medium">Enter Description *</span>
        <Textarea
          placeholder="Type here..."
          rows={8}
          className="min-h-[100px] resize-none"
          value={description}
          onChange={(event: any) => {
            setDescription(event.target.value)
          }}
        />
      </div>
      <div className="h-[200px]">
        <ImageDropzone
          image={logo}
          setImage={setLogo}
          className="h-[125px]"
          setDeleteLogo={setDeleteLogo}
        />
      </div>
      <div className="flex justify-between">
        <Button onClick={closeModal} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="success" disabled={disableSave}>
          Save
        </Button>
      </div>
    </div>
  )
}
